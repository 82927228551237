<template>
  <v-container fluid class="main-container">
    <v-row class="my-0">
      <v-col cols="12" sm="10" md="10" lg="10" xl="10" class="information-reminder my-0 py-0">
        <v-fade-transition>
          <div id="reminder-modal" class="modal" v-if="showReminderModal">
            <div class="modal-reminder-content">
              <v-row>
                <v-col class="mx-0 px-0 mt-3 mb-0 pb-0" cols="12" md="3" justify="left">
                  <v-img class="modal-logo" :src="require('../assets/images/patient_up_logo.png')" :contain="true" />
                </v-col>
              </v-row>
              <v-row>
                <hr class="gradient-line" />
              </v-row>
              <v-row>
                <h3 class="modal-reminder-title">{{ $t('reminder-title') }}</h3>
              </v-row>
              <v-row>
                <div class="modal-reminder-text">
                  <ul>
                    <li v-html="$t('reminder-msg-1')" />
                    <li v-html="$t('reminder-msg-2')" />
                    <li v-html="$t('reminder-msg-4')" />
                    <li v-html="$t('reminder-msg-5')" />
                  </ul>
                </div>
              </v-row>
              <v-row justify="center" class="modal-reminder-button-content">
                <v-btn class="modal-reminder-button" x-large @click="reminderAccepted()">
                  {{ $t('reminder-accept') }}
                </v-btn>
              </v-row>
            </div>
          </div>
        </v-fade-transition>
      </v-col>
    </v-row>
    <v-row class="main-screen">
      <v-col cols="12" md="6" class="sermes-image-content" ref="imageCol">
        <template v-if="!isMobile">
          <v-responsive :aspect-ratio="aspectRatio" class="sermes-image">
            <v-img :src="imageSrc" class="responsive-image" contain alt="Imagen" />
          </v-responsive>
        </template>
        <template v-else>
          <v-img :src="imageSrc" class="responsive-image mobile-image" contain alt="Imagen" />
        </template>
      </v-col>
      <v-col cols="12" md="6" class="information-content">
        <div class="content-wrapper">
          <v-row cols="12" class="language-icons">
            <locale-switcher />
          </v-row>
          <v-row class="right-side">
            <v-col cols="12" class="pb-0 mx-0 px-0 my-0 py-0">
              <v-card id="trial-information-form-card" class="form-card">
                <v-card-title primary-title class="justify-left pt-0" v-if="trialExpired == false">
                  <h3 class="step-title" id="trial-information-form-title">
                    {{ $t('trial-screen.title') }}
                  </h3>
                </v-card-title>
                <v-card-text v-if="trialExpired == false">
                  <v-form id="trial-information-form" ref="form" v-model="valid" lazy-validation>
                    <div ref="inicioDiv" class="trialInformationText" v-html="localizedTrialInformation"
                      @scroll="scrolled" @mousedown.prevent @selectstart.prevent />
                    <div class="info-checkbox">
                      <v-checkbox :class="[
                        instrRdDisabled ? 'trial-instruccions checkbox-disabled'
                          : 'trial-instruccions checkbox-enabled',
                        formSubmitted && !instrRd ? 'highlight-red' : '',
                      ]" v-model="instrRd" :disabled="instrRdDisabled">
                        <template v-slot:label>
                          <div :class="formSubmitted && !instrRd ? 'highlight-red-text' : ''" class="inline-elements">
                            <span>{{ $t('trial-screen.read') }}</span>
                            <span style="font-size: bold;">{{ $t('trial-screen.understand') }}</span>
                            <v-tooltip bottom :key="instructions">
                              <template v-slot:activator="{ on }">
                                <a v-if="instructions" :class="formSubmitted && !instrRd ? 'highlight-red-text'
                                  : 'trial-a inline-element'" target="_blank" :href="instructions" @click.stop
                                  v-on="on" rel="noopener noreferrer">
                                  <span>{{ $t('trial-screen.trial-instructions') }}</span>
                                  <span style="font-size: bold;">{{ $t('trial-screen.study') }}</span>
                                </a>
                                <span v-else
                                  :class="formSubmitted && !instrRd ? 'highlight-red-text' : 'instruction-text'">
                                  <span>{{ $t('trial-screen.trial-instructions') }}</span>
                                  <span style="font-size: bold;">{{ $t('trial-screen.study') }}</span>
                                </span>
                              </template>
                              {{ $t('trial-screen.trial-instructions-tooltip') }}
                            </v-tooltip>
                          </div>
                        </template>
                      </v-checkbox>
                      <v-checkbox id="term-and-conditions" :class="[
                        agreementDisabled ? 'trial-instruccions checkbox-disabled'
                          : 'trial-instruccions checkbox-enabled',
                        formSubmitted && !agree ? 'highlight-red' : '',
                      ]" v-model="agree" :disabled="agreementDisabled">
                        <template v-slot:label>
                          <div :class="formSubmitted && !agree ? 'highlight-red-text' : ''" class="inline-elements">
                            <span>{{ $t('trial-screen.agree') }}</span>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <a :class="formSubmitted && !agree ? 'highlight-red-text' : 'trial-a inline-element'"
                                  target="_blank" :href="termsAndConditionsUrl" @click.stop v-on="on"
                                  rel="noopener noreferrer">
                                  {{ $t('trial-screen.term-conditions') }}
                                </a>
                              </template>
                              {{ $t('trial-screen.term-conditions') }}
                            </v-tooltip>
                          </div>
                        </template>
                      </v-checkbox>
                      <v-checkbox id="privacy-policy" :class="[
                        privacyPolicyDisabled ? 'trial-instruccions checkbox-disabled'
                          : 'trial-instruccions checkbox-enabled',
                        formSubmitted && !privacyPolicy ? 'highlight-red' : '',
                      ]" v-model="privacyPolicy" :disabled="privacyPolicyDisabled">
                        <template v-slot:label>
                          <div :class="formSubmitted && !privacyPolicy ? 'highlight-red-text' : ''"
                            class="inline-elements">
                            <span>{{ $t('trial-screen.agree2') }}</span>
                            <v-tooltip bottom>
                              <template v-slot:activator="{ on }">
                                <a :class="formSubmitted && !privacyPolicy ? 'highlight-red-text' : 'trial-a inline-element'"
                                  target="_blank" :href="privacyPolicyUrl" @click.stop v-on="on"
                                  rel="noopener noreferrer">
                                  {{ $t('trial-screen.privacy-policy-details') }}
                                </a>
                              </template>
                              {{ $t('trial-screen.privacy-policy-details') }}
                            </v-tooltip>
                          </div>
                        </template>
                      </v-checkbox>
                    </div>
                  </v-form>
                </v-card-text>
                <v-card-actions class="justify-center pt-0 info-card-action" v-if="trialExpired == false">
                  <button id="patient-form-next-button" x-large :class="checkValidForm
                    ? 'button-enabled'
                    : 'button-disabled'" @click="nextStep()" @dblclick="preventDoubleClick" type="button">
                    {{ $t('trial-screen.button-next') }}
                  </button>
                </v-card-actions>
                <v-card-text v-if="trialExpired">
                  <h1 class="trial-expired">{{ $t('trial-screen.expired') }}</h1>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import { initializeApp } from 'firebase/app';
import { getAnalytics, logEvent, isSupported } from 'firebase/analytics';
import { trialServices } from '../services/trialService';
import LocaleSwitcher from './LocaleSwitcher.vue';
import getFirebaseConfig from '../utils/firebaseConfig';
import store from '../store/store';
import { coreServices } from '../services/coreService';
import patientUpLogo from '../assets/images/patient_up_logo.png';
import mujer01 from '../assets/images/mujer01.png';

export default {
  name: 'TrialInformation',
  components: {
    LocaleSwitcher,
  },
  data() {
    return {
      trial: '',
      trialExpired: false,
      step: 1,
      icons: ['mdi-information ', 'mdi-account', 'mdi-medical-bag', 'mdi-doctor'],
      trialInformation: '',
      trialName: '',
      shortTrialName: '',
      valid: false,
      studyRead: false,
      instructions: '',
      instrRd: false,
      agree: false,
      agreementDisabled: true,
      privacyPolicy: false,
      privacyPolicyDisabled: true,
      instrRdDisabled: true,
      showReminderModal: false,
      formSubmitted: false,
      localizedTrialInformation: '',
      termsAndConditionsUrl: coreServices.getTermsAndConditionsUrl(),
      privacyPolicyUrl: coreServices.getPrivacyPolicyUrl(),
      isCentered: false,
      aspectRatio: 0.796,
      analyticsInitialized: false,
    };
  },
  created() {
    this.trial = this.$route.params.trialId;
    this.$watch('$i18n.locale', this.updateLocalizedInformation);
    if (this.$store.state.cookiesAccepted === true) {
      this.initializeAnalytics();
    }
    trialServices.getTrialInformation(this.trial)
      .then((response) => {
        if (response === undefined) {
          store.dispatch('trialDataLoaded');
          this.$router.push('/404');
        } else {
          console.log('response.attachment_fil');
          console.log(response.attachment_file);
          const currentDate = new Date();
          let hasExpired = false;
          if (response.trial_expire_date) {
            const trialExpireDate = new Date(response.trial_expire_date.seconds * 1000);
            hasExpired = trialExpireDate <= currentDate;
          }
          if (hasExpired === true) {
            this.trialExpired = true;
            store.dispatch('trialDataLoaded');
          } else {
            if (response.attachment_file) {
              this.instructions = response.attachment_file;
            }
            this.trialExpired = false;

            try {
              if (response.trial_info.spanish === undefined) {
                this.trialInformation = JSON.parse(response.trial_info);
              } else {
                this.trialInformation = response.trial_info;
              }
            } catch (error) {
              console.error('Error al parsear trial_info:', error);
            }

            if (response.policy) {
              this.privacyPolicyUrl = response.policy;
            } else {
              this.privacyPolicyUrl = coreServices.getPrivacyPolicyUrl();
            }

            if (response.terms) {
              this.termsAndConditionsUrl = response.terms;
            } else {
              this.termsAndConditionsUrl = coreServices.getTermsAndConditionsUrl();
            }

            this.trialName = response.trial_name;
            this.shortTrialName = response.short_trial_name;
            this.updateLocalizedInformation();
            store.dispatch('trialDataLoaded');
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
    this.addEvent('first_visit', 'Trial Information view');
  },
  watch: {
    '$i18n.locale': 'updateLocalizedInformation',
  },
  computed: {
    ...mapGetters(['selectedLanguage', 'cookiesAccepted']),
    checkValidForm() {
      return this.agree && this.studyRead && this.instrRd && this.privacyPolicy;
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    imageSrc() {
      if (this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm) {
        return patientUpLogo;
      }
      return mujer01;
    },
  },
  methods: {
    async addEvent(eventName, passName) {
      /* eslint-disable no-underscore-dangle */
      window._paq = window._paq || [];
      window._paq.push(['trackEvent', eventName, eventName, eventName, passName]);
      if (this.analyticsInitialized) {
        logEvent(this.analytics, eventName, { name: eventName, arguments: passName });
      }
    },
    updateLocalizedInformation() {
      this.localizedTrialInformation = this.$i18n.locale === 'en'
        ? this.trialInformation.english : this.trialInformation.spanish;
    },
    nextStep() {
      if (!this.checkValidForm) {
        this.formSubmitted = true;
        this.openReminderModal();
        return;
      }
      this.step += 1;
      this.$emit('update', { step: this.step, trialName: this.trialName });
      this.addEvent('step1_next_buton', 'Step1 Next Button');
    },
    scrolled() {
      this.openReminderModal();
      const divElement = this.$refs.inicioDiv;
      const { scrollHeight, clientHeight: height, scrollTop } = divElement;
      const maxScroll = scrollHeight - height;
      if (Math.abs(maxScroll - scrollTop) <= 5) {
        this.studyRead = true;
        this.agreementDisabled = false;
        this.instrRdDisabled = false;
        this.privacyPolicyDisabled = false;
      }
    },
    openReminderModal() {
      this.showReminderModal = true;
    },
    reminderAccepted() {
      const modal = document.getElementById('reminder-modal');
      modal.style.display = 'none';
    },
    preventDoubleClick(event) {
      event.preventDefault();
    },
    async initializeAnalytics() {
      if (await isSupported()) {
        const app = initializeApp(getFirebaseConfig());
        this.analytics = getAnalytics(app);
        this.analyticsInitialized = true;
      }
    },
  },
};
</script>

<style scoped>
.main-screen {
  margin: 0;
}

.main-container {
  font-size: 1em;
  display: flex;
  flex-direction: column;
}

.information-content {
  margin: 0;
  padding: 0;
}

.language-icons {
  height: 7vh;
  margin: 1vh 0;
  padding-left: 0 !important;
  margin-left: 0 !important;
}

.sermes-image-content {
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  padding: 0;
}

.sermes-image {
  margin: 0;
  padding: 0;
  width: 100%;
}

.responsive-image {
  height: 100vh;
}

.right-side {
  width: 100%;
  margin: 0 auto;
  padding: 0;
}

.trial-expired {
  margin-top: 10vh;
  margin-left: 10%;
  font-family: 'Roboto-Regular';
  font-size: 1.7em;
  text-align: left;
  width: 70%;
  line-height: 7vh;
  color: var(--highlightedPU);
}

.instruction-text {
  font-family: 'Roboto-Bold';
  color: var(--highlightedPU);
}

.step-title {
  margin-top: 0;
  font-size: 1em;
  height: 5vh;
  font-family: 'Roboto-Regular';
  background: linear-gradient(90deg, #13c5e0, #2e90e0);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.trialInformationText {
  resize: none;
  cursor: auto;
  font-family: 'Roboto-Regular';
  overflow-y: auto;
  height: 47vh;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f9f9f9;
  scrollbar-width: thin;
  scrollbar-color: #13c5e0 #f1f1f1;
}

.trialInformationText::-webkit-scrollbar {
  width: 12px;
}

.trialInformationText::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.trialInformationText::-webkit-scrollbar-thumb {
  background-color: #13c5e0;
  border-radius: 10px;
  border: 3px solid #f1f1f1;
}

.trialInformationText::-webkit-scrollbar-thumb:hover {
  background-color: #2e90e0;
}

@-moz-document url-prefix() {
  .trialInformationText {
    scrollbar-width: thin;
    scrollbar-color: #13c5e0 #f1f1f1;
  }
}

.trial-a {
  font-family: 'Roboto-Bold';
  font-size: 1em;
  background: none !important;
  color: var(--highlightedPU) !important;
  text-decoration: none;
}

.form-card {
  margin: 0;
  padding: 0;
  box-shadow: none !important;
}

#patient-form-next-button {
  min-height: 45px;
  font-family: 'Roboto-Bold';
  border-radius: 23px;
  background: none !important;
  box-shadow: none !important;
  text-transform: uppercase;
  width: 30%;
}

button {
  text-transform: uppercase;
}

.inline-elements * {
  display: inline;
}

.highlight-red-text {
  color: red;
}

.checkbox-enabled>>>.v-label {
  font-family: 'Roboto-Bold';
  color: var(--typographyPU);
}

.trial-instruccions {
  font-family: 'Roboto-Regular';
  background: none !important;
  color: var(--highlightedPU) !important;
}

.trial-instruccions:first-of-type {
  padding-top: 2vh;
}

.info-checkbox {
  margin-top: 2em;
  height: auto;
}

.info-card-action {
  height: 11vh;
  padding-bottom: 5vh !important;
}

.button-enabled {
  border: solid var(--highlightedPU) 3px;
  color: var(--highlightedPU);
  opacity: 1;
}

.button-disabled {
  cursor: not-allowed ! important;
  border: solid var(--nonHighlightedTextPU) 3px;
  color: var(--nonHighlightedTextPU);
  opacity: 0.45;
}

.checkbox-enabled {
  opacity: 1;
  margin-top: -10px;
  font-family: 'Roboto-Bold';
  background: none !important;
  color: var(--highlightedPU) !important;
}

.checkbox-disabled {
  opacity: 0.5;
  margin-top: -10px;
  font-family: 'Roboto-Bold';
  background: none !important;
  color: var(--nonHighlightedTextPU) !important;
}

.step-counter {
  font-family: 'Roboto-Bold';
  font-weight: bold;
  font-size: 16px;
  background: none !important;
  color: var(--highlightedPU) !important;
}

.step-instructions {
  font-family: 'Roboto-Regular';
  font-weight: 400;
  font-size: 14px;
  color: var(--nonHighlightedTextPU) !important;
}

.checkbox-icon {
  background: none !important;
  color: var(--highlightedPU) !important;
}

/* Modal Styles */
.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  height: 100vh;
  z-index: 1;
  padding-top: 0;
  left: 0;
  top: 0;
  width: 100%;
  overflow: hidden;
  background-color: rgba(18, 197, 224, 0.5);
}

.information-reminder {
  position: absolute;
  z-index: 99;
  text-align: left;
  overflow: hidden;
}

.modal-reminder-content {
  background-color: #fefefe;
  margin: 0 auto;
  border: 1px solid #888;
  width: 60%;
  border-radius: 15px;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.85);
}

.modal-logo {
  height: 8vh;
  left: 5%;
}

.gradient-line {
  margin: auto;
  width: 87%;
  background: linear-gradient(90deg, #13c5e0, #2e90e0);
  height: 2px;
}

.modal-reminder-title {
  margin-top: 5vh;
  margin-left: 7%;
  margin-bottom: 3vh;
  font-size: 1.4em;
  color: var(--highlightedPU) !important;
}

.modal-reminder-text {
  overflow-y: auto;
  width: 85%;
  margin-left: 7%;
  opacity: 1;
  font-family: 'Roboto-Bold';
  font-size: 1.1em;
  line-height: 1.5em;
  background: none !important;
  color: var(--typographyPU) !important;
  text-align: left;
}

.modal-reminder-text ul {
  list-style-type: none;
  padding-left: 0;
}

.modal-reminder-text ul li {
  position: relative;
  padding-left: 1.5em;
  margin-top: 0.5em;
}

.modal-reminder-text ul li::before {
  content: "\2022";
  color: var(--highlightedPU);
  position: absolute;
  left: 0;
  font-size: 1em;
}

.modal-remind .highlight-red .v-input--selection-controls__input .v-icon {
  color: red !important;
}

.modal-reminder-button-content {
  height: 24vh;
}

.modal-reminder-button {
  height: 40px !important;
  width: 220px;
  margin-top: 9vh;
  text-align: center;
  font-family: 'Roboto-Bold';
  font-size: 1em;
  border-radius: 23px;
  background: none !important;
  border: solid var(--highlightedPU) 3px !important;
  color: var(--highlightedPU) !important;
  opacity: 1;
  box-shadow: none !important;
}

@media (max-width: 960px) {
  .main-screen {
    padding: 0 5%;
    flex-direction: column;
  }

  .sermes-image-content {
    height: auto;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .mobile-image {
    max-height: 10vh;
    max-width: 100%;
    display: block;
    margin: 0 auto;
    margin-top: 2vh;
  }

  .info-card-action {
    height: 20vh;
    padding-bottom: 10vh !important;
  }

  #patient-form-next-button {
    width: 40%;
  }

  .modal-reminder-content {
    width: 95%;
    padding: 0 5%;
  }

  .modal-reminder-text {
    font-size: 1em;
  }

  .modal-reminder-title {
    font-size: 1.2em;
  }

  .modal-logo {
    height: 8vh;
    left: 0;
  }

  .modal-reminder-button {
    height: 40px !important;
    width: 230px;
  }
}

@media (min-width: 961px) and (max-width: 1264px) {
  .main-screen {
    flex-direction: row;
  }

  .sermes-image-content {
    height: auto;
  }

  .responsive-image {
    height: auto;
  }

  .modal-reminder-content {
    width: 80%;
  }

  .modal-reminder-text {
    font-size: 1em;
  }

  .modal-reminder-title {
    font-size: 1.3em;
  }
}

@media (min-width: 1265px) {
  .main-screen {
    flex-direction: row;
  }

  .sermes-image-content {
    height: 100vh;
  }

  .responsive-image {
    height: 100vh;
  }

  .modal-reminder-content {
    width: 60%;
  }

  .modal-reminder-text {
    font-size: 1.1em;
  }

  .modal-reminder-title {
    font-size: 1.4em;
  }
}
</style>
